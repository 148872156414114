import Vue from "vue";
import VueRouter from "vue-router";
import AuthRequired from "./utils/AuthRequired";

Vue.use(VueRouter);

const routes = [
  {
    path: "/plataforma",
    component: () => import("./views/app/"),
    redirect: "/plataforma/mapa",
    beforeEnter: AuthRequired,
    children: [
      {
        path: "tienda",
        component: () => import("./views/app/components/Shop"),
        /*children: [
          {
            path:"/pedido",
            component: ()=>import("./views/app/components/Pedido"),
            //beforeEnter: AuthRequired,
          },
        ]*/
      },
      {
      path:"tienda/pedido",
        component: ()=>import("./views/app/components/Pedido"),
        //beforeEnter: AuthRequired,
      },
      {
        path: "dispositivo",
        component: () => import("./views/app/components/Dispositivo"),
        
      },
      {
        path: "perfil",
        component: () => import("./views/app/components/Perfil"),
        
      },
      {
        path: "mapa",
        component: () => import("./views/app/components/Maps"),
      },
      {
        path: "usuarios",
        component: () => import("./views/app/components/OtrosUsuarios"),
      },
      {
        path: "Dispositivos",
        component: () => import("./views/app/components/Dispositivos"),
      },
      {
        path: "app/blank-page",
        component: () =>
          import(/* webpackChunkName: "blank-page" */ "./views/app/blank-page")
      }
    ]
  },
  {
    path: "/panelmiddas",
    component: () => import("./views/admin/"),
    redirect: "/panelmiddas/dashboard",
    beforeEnter: AuthRequired,
    children: [
      /*{
        path:"dashboard",
        component: ()=>import("./views/admin/Dashboard")
      },*/
      {
        path:"dispositivos",
        component: ()=>import("./views/admin/Dispositivos")
      },
      {
        path:"pedidos",
        component: ()=>import("./views/admin/Pedidos")
      },
      {
        path:"usuarios",
        component: ()=>import("./views/admin/Usuarios")
      },
      {
        path:"planes",
        component: ()=>import("./views/admin/Planes")
      },
    ]
  },
  {
    path: "/",
    component: () => import("./views/landing/"),
    redirect: "/",
    children: [
      {
        path:"/",
        component: ()=>import("./views/landing/landpage")
      },
      {
        path:"payment",
        component: ()=>import("./views/landing/payment"),
        beforeEnter: AuthRequired,
      },
      {
        path:"register",
        component: ()=>import("./views/landing/register")
      },
      {
        path:"terms",
        component: ()=>import("./views/landing/terms")
      },
	  {
        path:"privacy",
        component: ()=>import("./views/landing/privacy")
   	  },
    ]
  },
  {
    path: "/error",
    component: () => import(/* webpackChunkName: "error" */ "./views/Error")
  },
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "user" */ "./views/user"),
    redirect: "/login/",
    beforeEnter: AuthRequired,
    children: [
      {
        path: "/",
        component: () =>
          import(/* webpackChunkName: "user" */ "./views/user/Login")
      },
      /*{
        path: "forgot-password",
        component: () =>
          import( "./views/user/ForgotPassword")
      },*/  
      /*{
        path: "reset-password",
        component: () =>
          import( "./views/user/ResetPassword")
      },*/
    ]
  },
  {
    path: "/verificacion",
    component: () => import(/* webpackChunkName: "user" */ "./views/user"),
    redirect: "/verificacion/",
    children: [
      {
        path: "/",
        component: () => 
          import("./views/user/Verificacion")
      }
    ]
  },
  {
    path: "/forgot-password",
    component: () => import(/* webpackChunkName: "user" */ "./views/user"),
    redirect: "/forgot-password/",
    children: [
      {
        path: "/",
        component: () => 
          import("./views/user/ForgotPassword")
      }
    ]
  },
  {
    path: "/reset-password",
    component: () => import(/* webpackChunkName: "user" */ "./views/user"),
    redirect: "/reset-password/",
    children: [
      {
        path: "/",
        component: () => 
          import("./views/user/ResetPassword")
      }
    ]
  },
  {
    path: "/verificar",
    component: () => import("./views/user"),
    redirect: "/verificar/",
    children:[
      {
        path: "/",
        component: () => 
          import("./views/user/Verificar")
      }
    ]
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "error" */ "./views/Error")
  }
];

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
